<template>
	<div ref="self" style="background: white; padding: 10px 20px">
		<el-form>
			<el-form-item label="浏览器内消息">
				<el-switch v-model="browser"></el-switch>
			</el-form-item>
			<el-alert v-if="!desktoPNotifySupported" :closable="false" type="info">您当前浏览器不支持，无法开启</el-alert>
			<el-form-item label="桌面消息">
				<el-switch v-model="desktop"></el-switch>
			</el-form-item>
			<el-form-item label="语音播报">
				<el-switch v-model="audio"></el-switch>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
import { Form, FormItem, Switch, Alert } from "element-ui";
import { getSettings, setSettings, desktoPNotifySupported} from '../../listener'
export default {
	name: "NotifySettings",
	components: {
		ElForm: Form,
		ElFormItem: FormItem,
		ElSwitch: Switch,
		ElAlert: Alert,
	},
	data() {
		return {
			browser: false,
			desktop: false,
			audio: false,
			desktoPNotifySupported
		};
	},
	mounted(){
		const { browser, desktop, audio } = getSettings()
		this.browser = browser
		this.desktop = desktop
		this.audio = audio
	},
	watch: {
		browser(){
			setSettings({
				browser: this.browser
			})
		},
		desktop(){
			setSettings({
				desktop: this.desktop
			})
		},
		audio(){
			setSettings({
				audio: this.audio
			})
		},
	}
};
</script>

<style>
</style>